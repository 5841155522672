<script>
export default { name: 'CheckoutView' }
</script>

<script setup>
import axios from 'axios';
import { useCart, useAppData } from '@/store';
import { onBeforeMount, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import MainLayout from '@/layouts/Layout.vue';
import { formatCurrency } from '@/composables/format';

const router = useRouter();
const { csrftoken, user } = storeToRefs(useAppData());
const { cart , order} = storeToRefs(useCart());
const { getActiveCart } = useCart();

console.log('🚀 ~ file: CheckoutView.vue:13 ~ cart', cart.value);

//const order = ref(cart());
console.log('🚀 ~ file: CheckoutView.vue:15 ~ order', order);
const items = ref([]);
const total = ref(null);

const shipping = ref(true);
const address = ref(null);
const city = ref(null);
const state = ref(null);
const zipcode = ref(null);

const loading = ref(false);

async function getOrder() {
  const response = await axios.get('/checkout').then(res => res.data);
  order.value = response.order;
  shipping.value = response.order.shipping;
  items.value = response.items;
  total.value = response.total
}

onBeforeMount(() => {
  getOrder();
})

async function submitFormData() {
  //console.log('Form Submitting...')
  loading.value = true;

  let userFormData = {
    name: user.value?.name,
    email: user.value?.email,
    total: total.value
  }

  let shippingInfo = {
    address: address.value,
    city: city.value,
    state: state.value,
    zipcode: zipcode.value
  }

  if (shipping.value) {
    shippingInfo.address = address.value
    shippingInfo.city = city.value
    shippingInfo.state = state.value
    shippingInfo.zipcode = zipcode.value
  }


  console.log('Shipping Info:', shippingInfo)
  console.log('User Info:', userFormData)

  try {
    const res = await axios.post(
      '/process_order/',
      JSON.stringify({ form: userFormData, shipping: shippingInfo }),
      {
        headers: {
          'Content-Type': 'applicaiton/json',
          'X-CSRFToken': csrftoken
        }
      }
    );
    console.log('Success:', res.data);

    document.cookie = 'cart=' + JSON.stringify({}) + ';domain=;path=/'

    router.push('/store');
    loading.value = false;
  } catch (err) {
    console.log('🚀 ~ file: CheckoutView.vue:80 ~ .then ~ err:', err);
  }
}

// const floatformat = (value = 0) => Number(value).toFixed(0);
const capfirst = (value = '') => String(value).charAt(0).toUpperCase() + String(value).toLowerCase().slice(1, String(value).length);

</script>
<template>
  <main-layout>
    <div class="form-row row-cols-lg-12">
      <div>
        <div class="form-group " id="form-wrapper">
          <form id="form" @submit="submitFormData" method="post">
            <div id="user-info">
              <div class="form-field">
                <label for="name">Nombre</label>
                <input required class="form-control" type="text" 
                id="name"
                name="name" placeholder="Name.." />
              </div>
              <div class="form-field">
                <label for="email">Email</label>
                <input required class="form-control" type="email" 
                id="email"
                name="email" placeholder="Email.." />
              </div>
            </div>

            <div id="shipping-info">
              <hr />
              <p>Dirección:</p>
              <hr />
              <div class="form-field">
                <input class="form-control" type="text" name="address" placeholder="Address.." />
              </div>
              <div class="form-field">
                <input class="form-control" type="text" name="city" placeholder="City.." />
              </div>
              <div class="form-field">
                <input class="form-control" type="text" name="state" placeholder="State.." />
              </div>
              <div class="form-field">
                <input class="form-control" type="text" name="zipcode" placeholder="Zip code.." />
              </div>
              <div class="form-field">
                <input class="form-control" type="text" name="country" placeholder="Zip code.." />
              </div>
            </div>

            <hr />

            <button
              id="form-button"
              v-show="!loading"
              class="btn btn-success btn-block"
              type="submit"
            >
              Comprar
              <br/>

            </button>

          </form>
        </div>

        <br />
        <div class="box-element hidden" id="payment-info" v-show="shipping && user && !loading">
          <small>Opciones de Pago</small>
          <div id="paypal-button-container"></div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="box-element">
          <router-link to="/cart" v-slot="{ href }">
            <a class="btn btn-outline-dark" :href="href">&#x2190; Volver al Carrito</a>
          </router-link>
          <hr />
          <h3>Resumen del Pedido</h3>
          <hr />
          <div class="cart-row" v-for="product in cart" :key="product.name + product.quantity">
            <div style="flex:2">
              <img class="row-image" v-bind:src="product.image" 
               :alt="product.name" />
            </div>
            <div style="flex:2">
              <p>{{ capfirst(product.name) }}</p>
            </div>
            <div style="flex:1">
              <p>{{ formatCurrency(product.price) }}</p>
            </div>
            <div style="flex:1">
              <p>{{ product.quantity }}</p>
            </div>
          </div>
          <h5>Items: {{ order.get_cart_items }}</h5>
          <h5>Total: {{ formatCurrency(order.get_cart_total) }}</h5>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<style></style>
