<!-- ChristmasHeader.vue -->
<template>
  <header class="christmas-header">
    <div class="snow-container">
      <div v-for="n in 50" :key="n" class="snowflake"
           :style="{
             left: Math.random() * 100 + '%',
             animationDuration: 5 + Math.random() * 10 + 's',
             animationDelay: Math.random() * 5 + 's'
           }">
        ❄
      </div>
    </div>
    <div class="christmas-decorations">
      <div class="christmas-lights">
        <div v-for="n in 10" :key="n" class="light"
             :style="{ left: (n-1) * 10 + '%' }"></div>
      </div>
      <h1><span class="tree">🎄</span> ¡Feliz Navidad 2024! <span class="santa">🎅</span></h1>
    </div>
  </header>
</template>

<script>
export default {
  name: 'ChristmasHeader'
}
</script>

<style scoped>
.christmas-header {
  position: relative;
  background: linear-gradient(to bottom, #1a365d, #2a4365);
  min-height: 120px;
  overflow: hidden;
  padding: 20px;
}

.snow-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.snowflake {
  position: absolute;
  color: white;
  font-size: 20px;
  animation-name: falling;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  opacity: 0.8;
}

@keyframes falling {
  0% {
    transform: translateY(-20px) rotate(0deg);
  }
  100% {
    transform: translateY(200px) rotate(360deg);
  }
}

.christmas-decorations {
  position: relative;
  z-index: 1;
  text-align: center;
}

.christmas-lights {
  position: absolute;
  top: 0;
  width: 100%;
  height: 10px;
}

.light {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: twinkle 1s infinite alternate;
}

.light:nth-child(odd) {
  background: #ff0000;
  animation-delay: 0.5s;
}

.light:nth-child(even) {
  background: #00ff00;
  animation-delay: 1s;
}

@keyframes twinkle {
  0% {
    opacity: 0.5;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

h1 {
  color: white;
  font-size: 2em;
  margin-top: 40px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.tree, .santa {
  display: inline-block;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
