<script>
export default {
  name: 'CartView',
}
</script>

<script setup>
import { useCart } from '@/store';
import { computed, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import MainLayout from '@/layouts/Layout.vue';
import { formatCurrency } from '@/composables/format';

const { cart, order } = storeToRefs(useCart());

const { getActiveCart, addProduct, deleteProduct } = useCart();

const floatformat = (value = 0) => Number(value).toFixed(0);
const capfirst = (value = '') => String(value).charAt(0).toUpperCase() + String(value).toLowerCase().slice(1, String(value).length);

const cartTotal = computed(() => {
  let sum = order.value['get_cart_total'] || 0;
  let desc = 0;
  if (order.get_totalDesc) desc = order.get_totalDesc;
  if (!order.value['get_cart_total']) {
    sum = cartSubtotal.value * Number(String('1.' + Number(desc)));
  }
  return floatformat(sum);
});
const cartSubtotal = computed(() => {
  let sum = 0;
  cart.value.forEach(element => {
    sum += element.price * element.quantity;
  });
  return floatformat(sum);
});
const cartItems = computed(() => {
  if (order['get_cart_items']) return floatformat(order['get_cart_items']);
  let sum = 0;
  cart.value.forEach(element => {
    sum += element.quantity;
  });
  return floatformat(sum);
});
onBeforeMount(() => {
  getActiveCart();
})


function setDefaultImage(productId) {
  let target = cart.value.find(e => e.id === productId)
  const index = cart.value.findIndex(e => e.id === productId)
  target.image = '/static/img/defecto.jpeg';
  cart.value[index] = target;
  cart.value = [...cart.value];
}
</script >

<template>
  <main-layout>
    <div class="d-flex flex-row flex-wrap container py-auto h-100 mt-4">
      <div class="grid bg-light">
        <div class="g-col-8">
          <div class="p-5">
            <div class="d-flex justify-content-between align-items-center mb-5">
              <h1 class="fw-bold mb-0 text-black">Carrito de compras</h1>
              <h6 class="mb-0 text-muted">{{ cart.length }} productos - {{ cartItems }} Items </h6>
            </div>
            <hr class="my-4">

            <div class="row mb-4" v-for="product in cart" v-bind:key="product.id">
              <div class="row d-flex justify-content-between align-items-center">
                <div class="col-md-2 col-lg-2 col-xl-2">
                  <img class="img-fluid rounded-3" v-bind:src="product.image" v-bind:alt="capfirst(product.name)"
                    v-on:error="setDefaultImage(product.id)" />
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3 py-3 text-start d-flex flex-column justify-content-start">
                  <h6 class="text-muted">
                    {{ product.category }}
                  </h6>
                  <h6 class="text-black mb-0">{{ capfirst(product.name) }}</h6>
                </div>
                <div class="col-md-3 col-lg-3 col-xl-2 d-flex d-inline-flex w-auto">
                  <button :disabled="product.quantity === 1" class="btn btn-link px-2" v-on:click="deleteProduct(product)">
                    <i class="fas fa-minus"></i>
                  </button>

                  <input disabled min="0" name="quantity" v-bind:value="product.quantity" type="number"
                    class="form-control form-control-sm qty-product p-0 ps-2 pe-2 w-auto text-center"
                  />

                  <button :disabled="product.quantity_available === product.quantity" class="btn btn-link px-2" v-on:click="addProduct(product)">
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div class="col-md-3 col-lg-2 col-xl-2 offset-lg-1 d-inline-flex w-auto">
                  <h6 class="mb-0">{{ formatCurrency(product.price) }}</h6>
                </div>
                <div class="col-md-1 col-lg-1 col-xl-1 text-end d-inline-flex bg-danger rounded-circle delete-button">
                  <a href="#!" class="text-white" v-on:click="deleteProduct(product, true)">
                    <i class="fas fa-times"></i>
                  </a>
                </div>
              </div>
              <hr class="my-4">
            </div>

            <div class="pt-5">
              <h6 class="mb-0">

                <router-link to="/store" v-slot="{ href }">
                  <a v-bind:href="href" class="text-body">
                    <i class="fas fa-long-arrow-alt-left me-2"></i>
                    Continuar comprando
                  </a>
                </router-link>
              </h6>
            </div>
          </div>
        </div>
        <div class="g-col-4 bg-dark">
          <div class="p-5 text-white">
            <h3 class="fw-bold mb-5 mt-2 pt-1">Resumen</h3>
            <hr class="my-4">

            <div class="d-flex justify-content-between mb-4">
              <h6 class="text-uppercase opacity-75">{{ Number(cartItems) }} Items </h6>
              <h5 class="opacity-75">{{ formatCurrency(cartSubtotal) }}</h5>
            </div>

            <hr class="my-4">

            <div class="d-flex justify-content-between mb-4">
              <h6 class="text-uppercase opacity-75">Descuentos Web: </h6>
              <h5 class="opacity-75">{{ formatCurrency(order.get_totalDesc) }}</h5>
            </div>

            <hr class="my-4">

            <div class="d-flex justify-content-between mb-5">
              <h5 class="text-uppercase">Total:</h5>
              <h5>{{ formatCurrency(cartTotal) }} </h5>
            </div>

            <router-link to="/checkout" v-slot="{ href }">
              <button v-bind:href="href" type="button" class="btn btn-primary btn-block btn-lg"
                data-mdb-ripple-color="dark">Confirmar</button>
            </router-link>


          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<style lang="scss" scoped>
  .qty-product {
    max-width: 45px;
  }

  .delete-button {
    cursor: pointer;
    display: inline-flex;
    width: 24px;
    height: 24px;
    font-size: 16px;
    line-height: 16px;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .card-body {
    max-height: 144px;
  }
</style>
