
export function getToken(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';')
    cookies.forEach((e) => {
      let cookie = e.trim();
      // does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
      }
    })
  }
  return cookieValue;
}

export default getToken;