<script>
export default {
  name: 'StoreView',
}
</script >

<script setup>
// import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import StoreLayout from '@/layouts/Store.vue';
import { useAppData, useCart } from '@/store';
import { onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import { formatCurrency } from '@/composables/format';
import { event, getAnalytics, logEvent } from 'vue-gtag';


const { activeProductList: products, pagination } = storeToRefs(useAppData());
const { cart } = storeToRefs(useCart());
const { getProductList } = useAppData();
const { addProduct } = useCart();
const route = useRoute();

const floatformat = (value = 0) => Number(value).toFixed(0);
const capfirst = (value = '') => String(value).charAt(0).toUpperCase() + String(value).toLowerCase().slice(1, String(value).length);

function checkIfAdded(product) {
  const prod = cart.value.find(el => el.id === product.id);
  return !!(prod);
}

async function changePage(page = 1) {
  if (page <= 0 || page > pagination.value.total_pages) return;
  const redirectStr = redirectToPage(page);
  window.location.search = redirectStr.split('/store?')[1];
  //const params = new Object();
  /* redirectStr.split('?')[1].split('&').forEach(el => {
    const element = el.split('=');
    params[element[0]] = element[1];
  }) */
  //await getProductList(null, params);
}

function redirectToPage(page = 1) {
  let query = route.query;
  query['page'] = page;
  const queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
  return '/store?' + queryString;
}

function setDefaultImage(productId) {
  let target = products.value.find(e => e.id === productId)
  const index = products.value.findIndex(e => e.id === productId)
  target.image = '/static/img/defecto.jpeg';
  products.value[index] = target;
  products.value = [...products.value];
}

function handleBuy(product) {
  event('add_to_cart', {
    'event-label': 'add',
    value: product.id,
  });
  addProduct(product);
}

onBeforeMount(() => {
  getProductList();
  // handleBuy();
})

</script>

<template>
  <store-layout>
    <div class="grid justify-content-start gap-3">
      <div class="g-col-12 g-col-xs-12 g-col-sm-6 g-col-md-6 g-col-lg-4 p-0" v-for="product in products"
        v-bind:key="product.id">
        <div class="card box-shadow mx-auto h-100">
          <img class="img-fluid" v-bind:src="product.image" v-bind:alt="capfirst(product.name)"
            v-on:error="setDefaultImage(product.id)" />

          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <h5 class="mb-0 truncated text-start">{{ capfirst(product.name) }}</h5>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <h5 class="text-dark mb-0">{{ formatCurrency(product.price) }} </h5>
            </div>

            <div class="d-flex justify-content-between mb-2 align-items-center">
              <p class="text-muted mb-0">
                Disponible:
                <span class="fw-bold">
                  {{ floatformat(product.quantity_available) }}
                </span>
              </p>
              <button v-bind:data-product="product.id" data-action="add" type="button" class="btn-sm"
                v-bind:class="['btn', 'add-btn', 'update-cart', !checkIfAdded(product) ? 'btn-outline-danger' : 'btn-danger']"
                v-on:click="handleBuy(product)">
                <svg v-if="!checkIfAdded(product)" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  fill="currentColor" class="bi bi-cart-plus-fill" viewBox="0 0 16 16">
                  <path
                    d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-cart-plus-fill" viewBox="0 0 16 16">
                  <path
                    d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <hr class="mt-auto" />

    <!-- Pagination -->
    <nav aria-label="Page navigation example" class="d-flex justify-content-center">
      <ul class="pagination">
        <li v-bind:class="['page-item', Number(pagination.active_page) === 1 && 'disabled']"
          v-on:click="changePage(Number(pagination.active_page) - 1)">
          <a class="page-link" href="#" aria-label="Anterior">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li v-for="page in pagination.total_pages" v-bind:key="page" class="page-item"
          v-on:click="changePage(Number(page))">
          <router-link class="page-link" v-bind:class="[Number(pagination.active_page) === page && 'active']"
            v-bind:to="redirectToPage(Number(page))">
            {{ page }}
          </router-link>
        </li>
        <li v-bind:class="['page-item', Number(pagination.active_page) === pagination.total_pages && 'disabled']"
          v-on:click="changePage(Number(pagination.active_page) + 1)">
          <a class="page-link" aria-label="Siguiente">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
    <!-- Pagination -->
  </store-layout>
</template>

<style lang="scss" scoped>
  .truncated {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size-adjust: inherit;
    font-size: 0.9rem;
  }

  .page-link {
    background-color: transparent;
    color: var(--mdb-gray-800, #4f4f4f);

    &.active {
      background-color: var(--mdb-blue, #2196f3);
      color: white;
    }
  }

  .box-shadow {
    -webkit-box-shadow: 0 1px 1px rgba(72, 78, 85, .6);
    box-shadow: 0 1px 1px rgba(72, 78, 85, .6);
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }

  .box-shadow:hover {
    -webkit-box-shadow: 0 20px 40px rgba(72, 78, 85, .6);
    box-shadow: 0 20px 40px rgba(72, 78, 85, .6);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }

  .card {
    min-height: 250px;
    border-radius: 25px;
    max-height: 420px;
    overflow: hidden;
  }

  .card img {
    max-height: calc(100% - 144px);
    object-fit: cover;
    margin: auto 0;
  }

  .card-body {
    max-height: 144px;
  }

  @media screen and (max-width: 768px) {
    .card {
      min-height: 320px;
    }
  }
</style>
