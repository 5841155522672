import axios from "axios";
import { event, getAnalytics, logEvent } from 'vue-gtag';

export async function getActiveProductList(search = '', query = {}) {

  if(query.category === undefined && query?.search === undefined) {
    query = { "category": "Fiesta" };
  }

  event('getActiveProductList', {
    'event-label': 'link_category',
    value: query?.category,
  });

  const searchQuery = query?.search || search ? '&search=' + query?.search || search : '';
  const categQuery = query?.category ? '&category=' + query?.category : '';
  const both = categQuery && search ? categQuery + searchQuery : '';
  const prices = query.min_price || query.max_price ? `&min_price=${query.min_price}&max_price=${query.max_price}` : '';
  const order_by = query.order_by ? `&order_by=${query.order_by}` : '';

  const response = await axios.get(
    '/store-items' + `?page=${query?.page ?? 1}${both ? both : searchQuery ? searchQuery : categQuery ? categQuery : ''}${prices}${order_by}`
  );
  return response.data;
}
