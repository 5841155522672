<!-- NewYearHeader.vue -->
<template>
  <header class="relative w-full h-[500px] overflow-hidden bg-gradient-to-r from-indigo-600 to-purple-600">
    <!-- Background Image with Year New Celebration Theme -->
    <div
        class="absolute inset-0 bg-cover bg-center opacity-40"
        style="background-image: url('static/img/newyear.webp')"
    ></div>

    <!-- Content Container -->
    <div class="relative z-10 flex flex-col md:flex-row items-center justify-between px-6 md:px-12 lg:px-24 h-full">
      <!-- Text Section -->
      <div class="text-white text-center md:text-left max-w-xl space-y-4">
        <h1 class="text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight">
          Cotillón Año Nuevo 2024
        </h1>
        <p class="text-xl md:text-2xl text-gray-200">
          ¡Celebra la fiesta más esperada con nuestros artículos exclusivos!
        </p>
        <div class="flex justify-center md:justify-start space-x-4 pt-6">
          <button
              class="bg-white text-purple-600 hover:bg-gray-100 px-6 py-3 rounded-full font-semibold transition duration-300 ease-in-out transform hover:scale-105"
          >
            Ver Productos
          </button>
          <button
              class="border-2 border-white text-white hover:bg-white hover:text-purple-600 px-6 py-3 rounded-full font-semibold transition duration-300 ease-in-out transform hover:scale-105"
          >
            Promociones
          </button>
        </div>
      </div>

      <!-- Image Section -->
      <div class="w-full md:w-1/2 lg:w-[400px] mt-8 md:mt-0 animate-bounce-slow">
        <img
            src="static/img/newyear.webp"
            alt="Cotillón Año Nuevo"
            class="w-full h-auto object-contain rounded-xl shadow-2xl"
        />
      </div>
    </div>

    <!-- Decorative Elements -->
    <div class="absolute bottom-0 left-0 right-0 h-16 bg-white/10 backdrop-blur-sm"></div>
  </header>
</template>

<script>
export default {
  name: 'NewYearHeader'
}
</script>

<style scoped>
.animate-bounce-slow {
  animation: bounce-slow 3s infinite;
}

@keyframes bounce-slow {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}
</style>
