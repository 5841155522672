<script setup>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { ref } from 'vue';

const currentSlide = ref(0);

const slideTo = (nextSlide) => (currentSlide.value = nextSlide);

const galleryConfig = {
  autoplay: 2000,
  itemsToShow: 2,
  wrapAround: true,
  mouseDrag: false,
  touchDrag: false,
};

const thumbnailsConfig = {
  itemsToShow: 4,
  wrapAround: true,
  gap: 10,
};

const slides = [
  {
    id: 2,
    title: '',
    content: '',
    url: '',
    img: '/static/img/Carousel/AnoNuevo2025.png',
  },
  {
    id: 3,
    title: '',
    content: '',
    url: 'https://www.instagram.com/reel/DCUHdz2xUWu/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
    img: '/static/img/Carousel/SuperZings.png',
  },
  {
    id: 4,
    title: '',
    content: '',
    url: 'https://www.instagram.com/reel/DBl289YR2MN/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
    img: '/static/img/Carousel/Pinata.png',
  },
  {
    id: 5,
    title: '',
    content: '',
    url: 'https://www.instagram.com/p/C9QazrgRN5Z/',
    img: '/static/img/Carousel/Mapa2.png',
  },
];

</script>

<template>
  <Carousel id="gallery"  v-bind="galleryConfig" v-model="currentSlide">
    <Slide v-for="slide in slides" :key="slide">
      <div class="carousel__item" >
        <a :href="slide.url" target="_blank">
          <img :src="slide.img" :alt="slide.content" />
        </a>
      </div>
    </Slide>
  </Carousel>

  <Carousel id="thumbnails" v-bind="thumbnailsConfig" v-model="currentSlide">
    <Slide v-for="slide in slides" :key="slide">
      <div class="carousel__item" @click="slideTo(slide - 1)">
        <img :src="slide.img" alt="Thumbnail" />
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<style>
:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  color-scheme: light dark;
  background-color: #242424;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --brand-color: #f6f2f2;
}
.carousel__item {
  width: 90%;
  background-color: var(--brand-color);
  color: #fff;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel__item img {
  width: 95%;
  border-radius: 5px;
  object-fit: cover;
}

.gallery-image {
  height: 300px;
}

.thumbnail-image {
  height: 100px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

.thumbnail-image:hover {
  opacity: 1;
}

#thumbnails {
  margin-top: 5px;
}
#thumbnails img {
  width: auto;
  height: 100px;
  opacity: 0.4;
}

.imgCarousel {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 0 auto;
  width: 90%;
  max-width: 500px;
  height: 420px;
  max-height: 420px;
  min-width: 100px;
}

#imgCarousel img{
  border: 3px solid ;
  position: absolute;
  top: 0px; left: 0px;
  background-size: cover;
  max-width: 70%;
  height: auto;
  max-height: 50%;
  object-fit: fill;


}

@media screen and (max-width: 600px) {
  .imgCarousel {
    background-size: cover;
    width: 50%;
    max-height: 360px;
    height: 100%;
    margin-left: 0;
  }
}

</style>
