import HomeView2 from "@/layouts/Carousel.vue";
import { createRouter, createWebHistory } from 'vue-router'
import CheckoutView from '@/views/CheckoutView.vue'
import CartView from '@/views/CartView.vue'
import StoreView from '@/views/StoreView.vue'
import HomeView from '@/views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: true,
    meta: {
      title: 'Happy House Fiesta'
    }
  },
  {
    path: '/store',
    name: 'store',
    component: StoreView,
    props: true,
    meta: {
      title: 'Happy House Fiesta - Store'
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView,
    props: true,
    meta: {
      title: 'Happy House Fiesta - Cart'
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutView,
    props: true,
    meta: {
      title: 'Happy House Fiesta - Checkout'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),// createMemoryHistory(process.env.VUE_APP_BASE_URL),// createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
  scrollBehavior: () => ({
    top: 0,
    left: 0,
    behavior: 'smooth',
  }),
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Happy House Fiesta'
  next()
})

export default router
