<script setup>
import { ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const body = ref(document.querySelector('body'));

watchEffect(() => {
  const isHome = route.path === '/'
  if (isHome) body.value.classList.add('home-view');
  else body.value.classList.remove('home-view');
})
</script>

<template>
  <router-view />
</template>

<style lang="scss">
  #app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    max-width: 100%;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-flow: column;
    gap: 16px;
    background-color: whitesmoke;
    overflow: hidden;
  }

  nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  body.home-view {
    #app {
      gap: 0px;
    }
  }

  body div#main-app {
    position: relative;
    max-height: calc(100vh);
    overflow: auto;
  }
</style>
