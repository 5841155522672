<script>
  export default { name: 'FooterApp' };
</script>

<script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';

  const isMovil = ref(false);

  const workSlogan = `
    Todo lo que necesitas para tus celebraciones. Cotillón de fiesta, decoración para baby shower, cumpleaños
    infantiles, graduaciones, bautizos y matrimonios. Globos, regalos, dulces, disfraces y más.
  `;

  function detectDeviceType() {
    const width = window.innerWidth;
    if (width < 768) {
      isMovil.value = true;
    } else if (width >= 768 && width < 992) {
      isMovil.value = false;
    } else {
      isMovil.value = false;
    }
  }

  const handleSocial = (social = '') => {
    let url;
    switch (social) {
      case 'whatsapp':
        // Reemplaza '1234567890' con el número de teléfono deseado
        url = 'https://api.whatsapp.com/send?phone=56978406554&amp;text=Hola Happy House! Quiero saber sobre...';
        break;
      case 'facebook':
        // Reemplaza 'nombreDeUsuario' con el nombre de usuario de Facebook
        url = 'https://web.facebook.com/profile.php?id=100088881668855';
        break;
      case 'instagram':
        // Reemplaza 'nombreDeUsuario' con el nombre de usuario de Instagram
        url = 'https://www.instagram.com/happy_house_fiesta/';
        break;
      default:
        console.error('Red social no soportada');
        return;
    }
    window.open(url, '_blank');
  }

  onMounted(() => {
    detectDeviceType();
    window.addEventListener('resize', detectDeviceType);
  })
  onBeforeUnmount(() => {
    window.removeEventListener('resize', detectDeviceType);
  })

</script>

<template>
  <footer class="pt-5 pb-3">
    <div class="container">
      <div class="row text-md-left text-center justify-content-center">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
          <h5>Sobre Nosotros</h5>
          <div class="footer-description text-center text-md-left mb-lg-0 mb-3">
            {{ workSlogan }}
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-lg-0 mb-4" v-if="isMovil">
          <h5>Síguenos</h5>

          <ul class="list-inline social-footer my-3">
            <button type="button" class="btn btn-outline-light btn-floating rounded instagram p-0" data-mdb-ripple-init
              @click="handleSocial('instagram')">
              <i class="fab fa-instagram"></i>
            </button>
            <button type="button" class="btn btn-outline-light btn-floating rounded facebook p-0" data-mdb-ripple-init
              @click="handleSocial('facebook')">
              <i class="fab fa-facebook"></i>
            </button>
            <button type="button" class="btn btn-outline-light btn-floating rounded whatsapp p-0" data-mdb-ripple-init
              @click="handleSocial('whatsapp')">
              <i class="fab fa-whatsapp"></i>
            </button>
          </ul>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-lg-0 mb-4 atencion text-xs-center">

          <ul style="list-style: none">
            <li class="text-sm-end text-xs-center">
              <a href="mailto:happyhousecolina@gmail.com" target="_blank" title="happyhousecolina@gmail.com">
                <i class="mr-2 far fa-envelope"></i>
                happyhousecolina@gmail.com
              </a>
            </li>
            <div class="footer-description text-center text-md-left mb-lg-0 mb-3">
              <li class="text-sm-end text-xs-center">
                <i class="mr-2 fas fa-clock"></i>
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;"> Lunes a Viernes 11:00 - 20:00 hrs.</font>
                </font>
                <br />
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;"> Sábado 11:00 - 20:00 hrs.</font>
                </font>
              </li>
              <li>
                <a target="_blank" href="https://www.google.com/maps/place/Happy+House+Fiesta/@-33.2158808,-70.6761113,17z/data=!3m1!4b1!4m6!3m5!1s0x9662bb0e1113c01d:0xbc47fb93e8df306f!8m2!3d-33.2158853!4d-70.673531!16s%2Fg%2F11twv0694d?hl=es-CL&entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                  </svg>

                <font style="vertical-align: inherit;">
                  Caletera oriente, Autop. los Libertadores 21160,
                </font>
                  <br>
                  <font style="vertical-align: inherit;">
                  Local 4, Colina, Región Metropolitana
                </font>
                </a>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>

    <hr class="mb-4 mt-0 border-dark" />

    <div class="marca-cr justify-center row flex-row flex-nowrap col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-0">
      <a href="https://www.happyhouse.cl" title="Happy House" class="ml-auto">
        <img src="static/img/Logo-no-background.png" alt="Happy House"
          class="navbar-brand img-fluid mx-auto p-0 pb-3" />
      </a>
      <p class="powered-by mr-auto">© 2024 Happy House. Todos los derechos reservados.</p>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  footer {
    color: #f1f1f1;
    background-color: #5C5C5B;
    position: relative;
    bottom: 0;
    margin-top: auto;

    .navbar-brand {
      max-width: 48px;
    }

    .atencion {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-end;

      @media screen and (max-width: 640px) {
        justify-content: flex-start;
        align-items: center;
      }
    }

    .marca-cr {
      justify-content: center;
      align-items: center;
      margin: auto;

      a {
        max-width: 56px;
      }

      .powered-by {
        width: auto;
        justify-content: flex-start;
        align-items: center;
        display: flex;
      }

      &.justify-center {
        justify-content: center;
      }
    }

    .instagram,
    .facebook,
    .whatsapp {
      color: #f1f1f1;
    }

    .facebook:hover,
    .facebook:active,
    .facebook:focus {
      background-color: rgb(59, 89, 152) !important;
    }

    .instagram:hover,
    .instagram:active,
    .instagram:focus {
      background-color: rgb(172, 43, 172) !important;
    }

    .whatsapp:hover,
    .whatsapp:active,
    .whatsapp:focus {
      background-color: rgb(37, 211, 102) !important;
    }
  }
</style>
