import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { getToken } from '@/composables';
import { useRoute } from 'vue-router';
import { useCart } from '@/store/cart';
import { getActiveProductList } from '@/composables/products';

const useAppData = defineStore('AppData', () => {
  const { cart, order, vistaCategories,vistaCategoriesFull } = storeToRefs(useCart());

  const csrftoken = ref(getToken('csrftoken'));

  const categories = computed(() => {
    const cats = vistaCategories.value;
    return cats.map((cat) => ({ ...cat.fields }));
  });

  const categoriesFull = computed(() => {
    const catsfull = vistaCategoriesFull.value;
    return catsfull.map((cat) => ({ ...cat.fields }));
  });

  const user = ref({
    name: '', email: ''
  });
  const prices = ref({
    max_price: 0,
    min_price: 0,
  });
  const pagination = ref({
    total_products: 0,
    product_per_page: 0,
    total_pages: 0,
    end_index: 0,
    start_index: 0,
    active_page: 1,
  });
  const activeProductList = ref([]);

  const { getActiveCart } = useCart();
  const { query } = useRoute();

  const reqCateg = computed(() => query?.category ?? '');

  /* Actions */
  async function getProductList(search = '', params = null) {
    const {
      order: orderProducts,
      products,
      total_products,
      product_per_page,
      total_pages,
      end_index,
      start_index,
      active_page,
      min_price,
      max_price,
    } = await getActiveProductList(search, params ?? query);

    activeProductList.value = products.map(product => ({ ...product.fields, id: product.pk }));
    prices.value = { max_price, min_price }
    pagination.value = {
      total_products,
      product_per_page,
      total_pages,
      end_index,
      start_index,
      active_page,
      orderProducts,
    };
    getActiveCart();
  }

  return {
    /* values */
    categories,
    categoriesFull,
    reqCateg,
    user,
    csrftoken,
    activeProductList,
    pagination,
    prices,
    cart,
    order,
    /* actions */
    getProductList,
    getActiveCart,
  }
})

export { useAppData, useCart };
