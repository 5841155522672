<template>
  <div class="banner-container" :class="{ 'animate-banner': isVisible }">
    <div class="banner-content">
      <div class="banner-header">
        <img src="/api/placeholder/50/50" alt="Birthday cake icon" class="cake-icon"/>
        <h2 class="title">¡SUPER DESCUENTOS DE CUMPLEAÑOS! 🎉</h2>
      </div>

      <div class="offer-details">
        <div class="discount-tag">
          <span class="discount-amount">50%</span>
          <span class="off-text">OFF</span>
        </div>

        <div class="product-list">
          <p class="product-item">🎂 Tortas personalizadas</p>
          <p class="product-item">🎈 Decoraciones</p>
          <p class="product-item">🎁 Sets de regalo</p>
        </div>

        <button class="cta-button" @click="handleClick">
          ¡Comprar Ahora!
        </button>
      </div>

      <div class="timer" v-if="showTimer">
        <p>Oferta termina en:</p>
        <div class="countdown">{{ timerDisplay }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BirthdaySaleBanner',
  data() {
    return {
      isVisible: true,
      showTimer: true,
      timeLeft: 172800, // 48 horas en segundos
      timerInterval: null
    }
  },
  computed: {
    timerDisplay() {
      const hours = Math.floor(this.timeLeft / 3600)
      const minutes = Math.floor((this.timeLeft % 3600) / 60)
      const seconds = this.timeLeft % 60
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }
  },
  methods: {
    handleClick() {
      this.$emit('banner-click')
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--
        } else {
          clearInterval(this.timerInterval)
          this.showTimer = false
        }
      }, 1000)
    }
  },
  mounted() {
    this.isVisible = true
    this.startTimer()
  },
  beforeDestroy() {
    clearInterval(this.timerInterval)
  }
}
</script>

<style scoped>
.banner-container {
  background: linear-gradient(135deg, #FF6B6B 0%, #FF8E53 100%);
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(50px);
  opacity: 0;
}

.animate-banner {
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.banner-content {
  color: white;
  text-align: center;
}

.banner-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.cake-icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
}

.offer-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.discount-tag {
  background: #FFD700;
  padding: 10px 20px;
  border-radius: 25px;
  display: inline-block;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.discount-amount {
  font-size: 32px;
  font-weight: bold;
  color: #FF4444;
}

.off-text {
  font-size: 20px;
  font-weight: bold;
  color: #FF4444;
  margin-left: 5px;
}

.product-list {
  margin: 20px 0;
}

.product-item {
  font-size: 18px;
  margin: 10px 0;
}

.cta-button {
  background: #FFD700;
  color: #FF4444;
  border: none;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s;
}

.cta-button:hover {
  transform: scale(1.05);
}

.timer {
  margin-top: 20px;
  font-size: 16px;
}

.countdown {
  font-size: 24px;
  font-weight: bold;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .title {
    font-size: 24px;
  }

  .discount-amount {
    font-size: 28px;
  }

  .product-item {
    font-size: 16px;
  }

  .cta-button {
    font-size: 18px;
    padding: 12px 25px;
  }
}
</style>
