<script>
  export default { name: "HeaderApp" };
</script>
<script setup>
  import newYear from "@/views/NewYearHeader.vue";
  import { computed, defineProps, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue';// Initialization for ES Users
  import { Collapse, Modal, Ripple, initMDB } from 'mdb-ui-kit';
  import router from '@/router';
  import ChristmasHeader from '@/views/ChristmasHeader.vue';
  import NewYearHeader from "@/views/NewYearHeader.vue";


  const props = defineProps({
    cart: {
      type: Number,
      default: 0
    },
    theme: {
      type: String,
      default: 'light'
    },
  });

  const cart = ref(props.cart);
  const theme = ref(props.theme);
  const show = ref(false);
  const deviceType = ref('');
  const route = computed(() => router.currentRoute.value);

  const rutasCelebraciones = [
    'Año Nuevo',
      'Baby Shower',
      'Bautizo',
    'Fiestas Patrias',
    'Halloween',
    'Mama',
    'Navidad',
    'Marco Selfie',
    'Pascua Resu',
    'Revelacion',
  ], rutasCotillones = [
    'Adhesivos',
    'Brillos',
    'Collares',
    'Cañones Y Confetti',
    'Cintillos',
    'Corbatas',
    'Coronas',
    'Fiesta',
    'Gorros',
    'Lentes',
    'Mascaras',
    'Pelucas',
  ], rutasCumpleanios = [
    'Accesorio Festejado',
    'Bandejas y Bases',
    'Bolsa Dulce',
    'Bombilla',
    'Brocheta',
    'Cortina',
    'Deco Torta',
    'Faldon',
    'Guirnalda',
    'Mantel',
    'Pack',
    'Piñata',
    'Plato',
    'Servicio',
    'Servilleta',
    'Vaso',
    'Vela',
  ], rutasDisfraz = [ 'Accesorios', 'Disfraz' ],
      rutasGlobo = [
    'Accesorio Globos',
    'Bases',
    'Bombita',
    'Burbuja',
    'Chrome',
    'Confetti',
    'Estandar X25',
    'Estandar X50',
    'Figuras',
    'Fluor',
    'Foil',
    'Helio',
    'Impresos',
    'Infladores',
    'Largos',
    'Letras',
    'Metal',
    'Números',
    'Pastel',
    'Retro',
    'Set de Globos',
  ],
    rutasJuguetes = ['Juguete Sorpresa', 'Plumeros', 'Superzings'],
  rutasOtros = [ 'Dulce', 'Sorpresa' ];

  function detectDeviceType() {
    show.value = false;
    const width = window.innerWidth;
    if (width < 768) {
      deviceType.value = 'movil';
    } else if (width >= 768 && width < 992) {
      deviceType.value = 'tablet';
    } else {
      deviceType.value = 'escritorio';
    }
  }

  const matcher = (ruta = '') => {
    return String(route.value.query.category).includes(ruta);
  }

  const handleSocial = (social = '') => {
    let url;
    switch (social) {
      case 'whatsapp':

        url = 'https://api.whatsapp.com/send?phone=56978406554&amp;text=Hola Happy House! Quiero saber sobre...';
        break;
      case 'facebook':

        url = 'https://web.facebook.com/profile.php?id=100088881668855';
        break;
      case 'instagram':

        url = 'https://www.instagram.com/happy_house_fiesta/';
        break;
      default:
        console.error('Red social no soportada');
        return;
    }
    window.open(url, '_blank');
  }

  watchEffect(() => {
    cart.value = props.cart;
    theme.value = props.theme;
  });

  onMounted(() => {
    initMDB({ Collapse, Modal, Ripple });
    detectDeviceType();
    window.addEventListener('resize', detectDeviceType);
  })
  onBeforeUnmount(() => {
    window.removeEventListener('resize', detectDeviceType);
  })

</script>

<template>
  <!--
  <christmas-header v-if="rutasCelebraciones.some(matcher)"></christmas-header>
  <new-year-header v-if="rutasCelebraciones.some(matcher)"></new-year-header>
  -->

  <christmas-header></christmas-header>

  <header :class="theme" class="position-sticky top-0">
    <nav class="navbar navbar-expand-lg navbar row flex-column p-0 m-auto w-100">
      <div class="container-fluid px-2 image-bar">
        <a class="navbar-brand mr-auto" href="/">
          <img src="static/img/Logo-color-background.png" style="width: 38px;border-radius: 8px;" alt="Happy House">
        </a>
        <form class="needs-validation" id="form" method="get" action="/store" v-if="deviceType !== 'movil'">
          <div class="d-flex" role="search">
            <label for="search" class="form-label"></label>
            <input id="search" class="form-control me-2" type="text" placeholder="search" aria-label="search" required
              pattern=".{4,}" name="search">
            <button class="btn btn-outline-success" id="btsearch" type="submit">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
            <div class="invalid-feedback">
              Debe ingresar 4 caracteres
            </div>
          </div>
        </form>

        <button type="button" data-mdb-ripple-init data-mdb-ripple-color="dark" @click="router.push('/cart')" class="btn btn-outline-light cart-navigator mx-3">
          <img id="cart-icon" src="static/img/cart.png" alt="cart">
          <span class="badge rounded-pill badge-notification bg-danger position-absolute">
            {{ cart.length }}
            <span class="visually-hidden">Contenido del Carrito</span>
          </span>
        </button>

        <div class="contact-info" v-if="deviceType !== 'movil'">
          <button type="button" class="btn btn-outline-light btn-floating rounded instagram p-0" data-mdb-ripple-init @click="handleSocial('instagram')">
            <i class="fab fa-instagram"></i>
          </button>
          <button type="button" class="btn btn-outline-light btn-floating rounded facebook p-0" data-mdb-ripple-init @click="handleSocial('facebook')">
            <i class="fab fa-facebook"></i>
          </button>
          <button type="button" class="btn btn-outline-light btn-floating rounded whatsapp p-0" data-mdb-ripple-init @click="handleSocial('whatsapp')">
            <i class="fab fa-whatsapp"></i>
          </button>
        </div>

        <button v-show="deviceType === 'movil'" type="button" class="navbar-toggler" data-mdb-ripple-init
          data-mdb-modal-init data-mdb-target="#rutasCats">
          <i class="fas fa-bars"></i>
        </button>
      </div>

      <div class="row w-100 cat-bar p-0" v-if="deviceType !== 'movil'">
        <div class="btn-group justify-content-center w-100 p-0" role="group"
          aria-label="Button group with nested dropdown">
          <div class="btn-group">
            <button id="btnGroupDrop1" type="button" data-toggle="dropdown" class="text-capitalize btn btn-link dropdown-toggle"
              aria-expanded="false" aria-haspopup="true" data-mdb-ripple-init data-mdb-dropdown-init>
              Celebraciones
            </button>
            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <a class="dropdown-item pb-1 p-1" v-for="ruta in rutasCelebraciones" :key="String(ruta).trim().split(' ').join()"
                :href="'store?category=' + ruta">
                {{ ruta }}
              </a>
            </div>
          </div>
          <div class="btn-group">
            <button id="btnGroupDrop1" type="button" data-toggle="dropdown" class="text-capitalize btn btn-link dropdown-toggle"
              aria-expanded="false" aria-haspopup="true" data-mdb-ripple-init data-mdb-dropdown-init>
              Cotillon
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item pb-1 p-1" v-for="ruta in rutasCotillones" :key="String(ruta).trim().split(' ').join()"
                 :href="'store?category=' + ruta">
                {{ ruta }}
              </a>
            </div>
          </div>
          <div class="btn-group">
            <button id="btnGroupDrop1" type="button" data-toggle="dropdown" class="text-capitalize btn btn-link dropdown-toggle"
              aria-expanded="false" aria-haspopup="true" data-mdb-ripple-init data-mdb-dropdown-init>
              Cumpleaños
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item pb-1 p-1" v-for="ruta in rutasCumpleanios" :key="String(ruta).trim().split(' ').join()"
                :href="'store?category=' + ruta">
                {{ ruta }}
              </a>
            </div>
          </div>
          <div class="btn-group">
            <button id="btnGroupDrop1" type="button" data-toggle="dropdown" class="text-capitalize btn btn-link dropdown-toggle"
              aria-expanded="false" aria-haspopup="true" data-mdb-ripple-init data-mdb-dropdown-init>
              Disfraz
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item pb-1 p-1" v-for="ruta in rutasDisfraz" :key="String(ruta).trim().split(' ').join()"
                 :href="'store?category=' + ruta">
                {{ ruta }}
              </a>
            </div>
          </div>
          <div class="btn-group">
            <button id="btnGroupDrop1" type="button" data-toggle="dropdown" class="text-capitalize btn btn-link dropdown-toggle"
              aria-expanded="false" aria-haspopup="true" data-mdb-ripple-init data-mdb-dropdown-init>
              Globo
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item pb-1 p-1" v-for="ruta in rutasGlobo" :key="String(ruta).trim().split(' ').join()"
                 :href="'store?category=' + ruta">
                {{ ruta }}
              </a>            </div>
          </div>
          <div class="btn-group">
            <button id="btnGroupDrop1" type="button" data-toggle="dropdown" class="text-capitalize btn btn-link dropdown-toggle"
              aria-expanded="false" aria-haspopup="true" data-mdb-ripple-init data-mdb-dropdown-init>
              Juguetes
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item pb-1 p-1" v-for="ruta in rutasJuguetes" :key="String(ruta).trim().split(' ').join()"
                :href="'store?category=' + ruta">
                {{ ruta }}
              </a>
            </div>
          </div>
          <div class="btn-group">
            <button id="btnGroupDrop1" type="button" data-toggle="dropdown" class="text-capitalize btn btn-link dropdown-toggle"
              aria-expanded="false" aria-haspopup="true" data-mdb-ripple-init data-mdb-dropdown-init>
              Otros
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item pb-1 p-1" v-for="ruta in rutasOtros" :key="String(ruta).trim().split(' ').join()"
                :href="'store?category=' + ruta">
                {{ ruta }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div v-show="deviceType === 'movil'" class="modal fade m-0 p-0" id="rutasCats" tabindex="-1"
        aria-labelledby="rutasCatsLabel" aria-hidden="true" data-mdb-modal-non-invasive="true">
        <div class="modal-dialog modal-fullscreen m-0 p-0">
          <div class="modal-content">
            <div class="modal-header">
              <a class="navbar-brand mr-auto" href="/">
                <img
                  src="static/img/Logo-color-background.png"
                  style="width: 38px;border-radius: 8px;"
                  alt="Happy House"
                />
              </a>
              <h5 class="modal-title" id="rutasCatsLabel">Happy House Fiesta</h5>
              <button type="button" class="btn-close" data-mdb-ripple-init data-mdb-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body d-flex flex-nowrap flex-column w-100">
              <button class="text-capitalize btn btn-light mb-2" type="button" data-mdb-collapse-init data-mdb-ripple-init
                data-mdb-target="#collapseCELEBRACIONES" aria-expanded="false" aria-controls="collapseCELEBRACIONES">
                Celebraciones
              </button>
              <ul class="list-group-small list-group-light grid p-0 m-0 g-0" id="CELEBRACIONES">
                <li v-for="ruta in rutasCelebraciones" :key="String(ruta).trim().split(' ').join()"
                  class="list-group-item px-3 border-0 g-col-6" :class="matcher(ruta) && 'active'"
                  :aria-current="matcher(ruta)">
                  <a :href="'store?category=' + ruta" :class="matcher(ruta) && 'active-link'">
                    {{ ruta }}
                  </a>
                </li>
              </ul>
              <hr />

              <button class="text-capitalize btn btn-light mb-2" type="button" data-mdb-collapse-init data-mdb-ripple-init
                data-mdb-target="#collapseCOTILLON" aria-expanded="false" aria-controls="collapseCOTILLON">
                Cotillón
              </button>
              <ul class="list-group-small list-group-light grid p-0 m-0" id="COTILLON">
                <li v-for="ruta in rutasCotillones" :key="String(ruta).trim().split(' ').join()"
                  class="list-group-item px-3 border-0 g-col-6 m-auto" :class="matcher(ruta) && 'active'"
                  :aria-current="matcher(ruta)">
                  <a :href="'store?category=' + ruta" :class="matcher(ruta) && 'active-link'">
                    {{ ruta }}
                  </a>
                </li>
              </ul>
              <hr />

              <button class="text-capitalize btn btn-light mb-2" type="button" data-mdb-collapse-init data-mdb-ripple-init
                data-mdb-target="#collapseCUMPLEAÑOS" aria-expanded="false" aria-controls="collapseCUMPLEAÑOS">
                Cumpleaños
              </button>
              <ul class="list-group-small list-group-light grid p-0 m-0" id="CUMPLEAÑOS">
                <li v-for="ruta in rutasCumpleanios" :key="String(ruta).trim().split(' ').join()"
                  class="list-group-item px-3 border-0 g-col-6" :class="matcher(ruta) && 'active'"
                  :aria-current="matcher(ruta)">
                  <a :href="'store?category=' + ruta" :class="matcher(ruta) && 'active-link'">
                    {{ ruta }}
                  </a>
                </li>
              </ul>
              <hr />

              <button class="text-capitalize btn btn-light mb-2" type="button" data-mdb-collapse-init data-mdb-ripple-init
                data-mdb-target="#collapseDISFRAZ" aria-expanded="false" aria-controls="collapseDISFRAZ">
                Disfraz
              </button>
              <ul class="list-group-small list-group-light grid p-0 m-0" id="DISFRAZ">
                <li v-for="ruta in rutasDisfraz" :key="String(ruta).trim().split(' ').join()"
                  class="list-group-item px-3 border-0 g-col-6" :class="matcher(ruta) && 'active'"
                  :aria-current="matcher(ruta)">
                  <a :href="'store?category=' + ruta" :class="matcher(ruta) && 'active-link'">
                    {{ ruta }}
                  </a>
                </li>
              </ul>
              <hr />

              <button class="text-capitalize btn btn-light mb-2" type="button" data-mdb-collapse-init data-mdb-ripple-init
                data-mdb-target="#collapseGLOBO" aria-expanded="false" aria-controls="collapseGLOBO">
                Globo
              </button>
              <ul class="list-group-small list-group-light grid p-0 m-0" id="GLOBO">
                <li v-for="ruta in rutasGlobo" :key="String(ruta).trim().split(' ').join()"
                  class="list-group-item px-3 border-0 g-col-6" :class="matcher(ruta) && 'active'"
                  :aria-current="matcher(ruta)">
                  <a :href="'store?category=' + ruta" :class="matcher(ruta) && 'active-link'">
                    {{ ruta }}
                  </a>
                </li>
              </ul>
              <hr />

              <button class="text-capitalize btn btn-light mb-2" type="button" data-mdb-collapse-init data-mdb-ripple-init
                data-mdb-target="#collapseJUGUETES" aria-expanded="false" aria-controls="collapseJUGUETES">
                Juguetes
              </button>
              <ul class="list-group-small list-group-light grid p-0 m-0" id="JUGUETES">
                <li v-for="ruta in rutasJuguetes" :key="String(ruta).trim().split(' ').join()"
                  class="list-group-item px-3 border-0 g-col-6" :class="matcher(ruta) && 'active'"
                  :aria-current="matcher(ruta)">
                  <a :href="'store?category=' + ruta" :class="matcher(ruta) && 'active-link'">
                    {{ ruta }}
                  </a>
                </li>
              </ul>
              <hr />

              <button class="text-capitalize btn btn-light mb-2" type="button" data-mdb-collapse-init data-mdb-ripple-init
                data-mdb-target="#collapseOTROS" aria-expanded="false" aria-controls="collapseOTROS">
                Otros
              </button>
              <ul class="list-group-small list-group-light grid p-0 m-0" id="OTROS">
                <li v-for="ruta in rutasOtros" :key="String(ruta).trim().split(' ').join()"
                  class="list-group-item px-3 border-0 g-col-6" :class="matcher(ruta) && 'active'"
                  :aria-current="matcher(ruta)">
                  <a :href="'store?category=' + ruta" :class="matcher(ruta) && 'active-link'">
                    {{ ruta }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
  header {
    display: flex;
    flex-flow: column;
    height: auto;
    width: 100%;
    z-index: 99;

    &.light {
      background: white;
    }

    &.fun {
      background: #007bff !important;
    }

    .modal-header {
      align-items: center;
    }

    .navbar-brand {
      img {
        max-width: 48px;
      }
    }

    img {
      max-width: 32px;
    }

    a>img,
    button>img {
      max-width: 24px;
    }

    .cart-navigator {
      display: flex;
      width: 44px;
      height: 44px;
      position: relative;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 50%;

      span {
        top: 4px;
        right: 4px;
      }
    }

    .image-bar {
      max-height: 64px;
    }

    .cat-bar {
      max-height: 48px;
      height: 100% !important;

      .btn-group {
        box-shadow: 0 0 0 transparent !important;
      }
    }
  }

  nav a.active-link {
    color: white;
  }

  .modal-categorias {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: white;
    padding: 24px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    transition: transform 0.36s ease, opacity 0.18s .18s ease;
  }

  .slide-enter-active,
  .slide-leave-active {
    opacity: 1;
    transform: translateX(0);
  }

  .slide-enter,
  .slide-leave-to

  /* .slide-leave-active en versiones anteriores de Vue */
    {
    transform: translateX(100%);
    opacity: 0;
  }

  hr {
    border-top: 1px solid rgba(0, 0, 0, .75);
  }

  .modal-dialog.modal-fullscreen {
    z-index: 9999;
  }

  .btn-light {
    min-height: 40px;
    height: 40px;
  }

  .rounded {
    border-radius: 50% !important;
  }

  .instagram,
  .facebook,
  .whatsapp {
    color: #5C5C5B;

    &:hover,
    &:active,
    &:focus {
      color: #f1f1f1;
    }
  }

  .facebook:hover,
  .facebook:active,
  .facebook:focus {
    background-color: rgb(59, 89, 152) !important;
  }

  .instagram:hover,
  .instagram:active,
  .instagram:focus {
    background-color: rgb(172, 43, 172) !important;
  }

  .whatsapp:hover,
  .whatsapp:active,
  .whatsapp:focus {
    background-color: rgb(37, 211, 102) !important;
  }
</style>
