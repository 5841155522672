import { getFromLocalStorage } from '@/composables';
import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCart = defineStore('useCartStore', () => {
  const vistaCategories = ref([])
  const vistaCategoriesFull = ref([])

  const cart = ref(JSON.parse(getFromLocalStorage('cart') ?? '[]'));
  const order = ref({});

  if (!cart.value) {
    cart.value = {};
    //console.log('cart created!', cart.value);
  }

  /* actions */
  const addProduct = (product = {}) => {
    if (!cart.value || !cart.value.length) cart.value.push({ ...product, quantity: 1 });
    let prod = cart.value.find(prod => prod.id === product.id);
    if (prod) {
      if (!prod.quantity_available) {
        return;
      } else {
        if (prod.quantity_available > prod.quantity) prod.quantity += 1;
        else return;
      }
    } else cart.value.push({ ...product, quantity: 1 });
    getActiveCart();
  }

  const deleteProduct = (product = {}, total = false) => {
    if (!cart.value || !cart.value.length) return;
    let prod = cart.value.find(prod => prod.id === product.id);
    if (!prod) return;
    else {
      if (total) cart.value = cart.value.filter(prod => prod.id !== product.id);
      if (prod.quantity > 1) prod.quantity -= 1;
      else cart.value = cart.value.filter(prod => prod.id !== product.id);
    }
    getActiveCart();
  }

  async function getActiveCart() {
    document.cookie = 'cart=' + JSON.stringify(cart.value) + ';domain=;path=/';
    localStorage.setItem('cart', JSON.stringify(cart.value));
    const response = await axios.get(
      '/cart', { withCredentials: true }
    );

    // cart.value = response.data['items'];

    order.value = response.data['order'];
    vistaCategories.value = response.data['vistaCategories'];
    getCategoryFull();
  }

  async function getCategoryFull() {
    const response = await axios.get(
        '/categoryfull', { withCredentials: true }
    );
    vistaCategoriesFull.value = response.data['vistaCategoriesFull'];
  }

  return {
    vistaCategories,
    cart,
    order,
    addProduct,
    deleteProduct,
    getActiveCart,
    vistaCategoriesFull,
  }
})
