import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios from 'axios';
import { createPinia } from 'pinia';
import vueCookies from 'vue-cookies';
import VueGtag from 'vue-gtag';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "vue-material-design-icons/styles.css";

const pinia = createPinia();

createApp(App)
    .use(
        VueGtag,
        {
            appName: 'Happy House',
            pageTrackerScreenviewEnabled: true,
            config:
                { id: process.env.VUE_APP_GA_ID }
        }
    )
    .use(pinia)
    .use(vueCookies, { expires: '1d' })
    .use(router, axios)
    .mount('#app')
