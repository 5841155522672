<script>
export default {name: "StoreLayout"};
</script>

<script setup>
import {useAppData, useCart} from '@/store';
import {storeToRefs} from 'pinia';
import {onMounted, ref, watchEffect} from "vue";
import { useRoute } from 'vue-router';
import { Input, initMDB } from "mdb-ui-kit";
import HeaderApp from './HeaderApp.vue';
import FooterApp from './FooterApp.vue';

const {reqCateg, prices, categoriesFull} = storeToRefs(useAppData());
const {cart} = storeToRefs(useCart());
const {query} = useRoute();

const capfirst = (value = '') => String(value).charAt(0).toUpperCase() + String(value).toLowerCase().slice(1, String(value).length);

const actualMin = ref(query?.min_price || prices.value.min_price || 0);
const actualMax = ref(query?.max_price || prices.value.max_price || 1);
const order_by = ref(query?.order_by || 'asc');

watchEffect(() => {
  actualMin.value = prices.value.min_price;
  actualMax.value = prices.value.max_price;
});

const handlePriceMin = (e) => {
  const n = Number(e.target.value)
  if (n >= prices.value.max_price) actualMin.value = prices.value.max_price - 1;
  else if (n >= actualMax.value) actualMin.value = actualMax.value - 1;
  else if (n >= prices.value.min_price) actualMin.value = n;
  else actualMin.value = prices.value.min_price;
};
const handlePriceMax = (e) => {
  const n = Number(e.target.value)
  if (n <= prices.value.min_price) actualMax.value = prices.value.min_price + 1;
  else if (n <= actualMin.value) actualMax.value = actualMin.value + 1;
  else if (n <= prices.value.max_price) actualMax.value = n;
  else actualMax.value = prices.value.max_price;
};
const resetPrices = () => {
  actualMin.value = prices.value.min_price;
  actualMax.value = prices.value.max_price;
};

const updateProductList = () => {
  const params = new URLSearchParams(window.location.search);
  if (actualMin.value >= 0) params.set('min_price', actualMin.value);
  if (actualMax.value >= 1) params.set('max_price', actualMax.value);
  if (order_by.value) params.set('order_by', order_by.value);
  window.location.search = params.toString();
};

onMounted(() => {
  initMDB({ Input });
})
</script>

<template>
  <div id="main-app">
    <header-app :cart="cart" />
    <div class="container d-flex flex-row flex-wrap mt-4 p-0 store-container">
      <!-- Collapsible wrapper -->
      <div class="col-lg-3 p-0">
        <div class="collapse card d-lg-block mb-5 sticky-top top-navbar" id="navbarSupportedContent">
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button text-dark bg-light" type="button" data-mdb-toggle="collapse"
                  data-mdb-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne">
                  Categorias
                </button>
              </h2>
              <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse"
                aria-labelledby="headingOne">
                <div class="accordion-body p-2">
                  <ul class="list-unstyled">
                    <li v-for="cat in categoriesFull" :key="cat.category" class="category-element">
                      <a :class="['nav-link opacity-80',{ 'active': cat.category === reqCateg }]"
                        :href="'store?category='+ cat.category" class="text-"> {{ capfirst(cat.category) }}
                        ({{ cat.cant }}) </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button text-dark bg-light" type="button" data-mdb-toggle="collapse"
                  data-mdb-target="#panelsStayOpen-collapseThree" aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree">
                  Precio
                </button>
              </h2>
              <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show"
                aria-labelledby="headingThree">
                <div class="accordion-body">
                  <div class="row mb-2 gap-4">
                    <div class="form-outline" data-mdb-input-init>
                      <input type="number" id="formControlMin" class="form-control form-control-lg" v-model="actualMin"
                        :max="actualMax - 1" @change="handlePriceMin" style="left: 0" />
                      <label class="form-label" for="formControlMin">Min.</label>
                    </div>
                    <div class="form-outline" data-mdb-input-init>
                      <input type="number" id="formControlMax" class="form-control form-control-lg" v-model="actualMax"
                        :min="actualMin + 1" @change="handlePriceMax" style="left: 0" />
                      <label class="form-label" for="formControlMax">Max.</label>
                    </div>
                  </div>
                  <div class="row flex-nowrap">
                    <button @click="resetPrices" type="button" class="btn w-auto flex-grow-1 btn-warning">
                      Limpiar
                    </button>

                    <button @click="updateProductList" type="button" class="btn w-auto flex-grow-1 btn-success">
                      Aplicar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button text-dark bg-light" type="button" data-mdb-toggle="collapse"
                  data-mdb-target="#panelsStayOpen-collapseFour" aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFour">
                  Orden
                </button>
              </h2>
              <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse show"
                aria-labelledby="headingThree">
                <div class="accordion-body">
                  <div class="input-group mb-3">
                    <!-- Checked radio -->
                    <div class="form-check">
                      <input class="form-check-input" type="radio" value="asc" id="flexCheckChecked1"
                        v-model="order_by" />
                      <label class="form-check-label" for="flexCheckChecked1">Nombre - Ascendente</label>
                    </div>
                    <!-- Checked radio -->
                    <div class="form-check">
                      <input class="form-check-input" type="radio" value="desc" id="flexCheckChecked2"
                        v-model="order_by" />
                      <label class="form-check-label" for="flexCheckChecked2">Nombre - Descendente</label>
                    </div>
                    <!-- Checked radio -->
                    <div class="form-check">
                      <input class="form-check-input" type="radio" value="price-asc" id="flexCheckChecked3"
                        v-model="order_by" />
                      <label class="form-check-label" for="flexCheckChecked3">Precio - Ascendente</label>
                    </div>
                    <!-- Checked radio -->
                    <div class="form-check">
                      <input class="form-check-input" type="radio" value="price-desc" id="flexCheckChecked4"
                        v-model="order_by" />
                      <label class="form-check-label" for="flexCheckChecked4">Precio - Descendente</label>
                    </div>
                  </div>
                  <button @click="updateProductList" type="button" class="btn btn-white w-100 border border-secondary">
                    Aplicar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 ps-3 pe-0 d-flex flex-column ">
        <slot></slot>
      </div>
    </div>
    <footer-app />
  </div>
</template>

<style lang="scss" scoped>

  #main-app {
    display: flex;
    flex-flow: column;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 98;
  }

  #cart-icon {
    width: 20px;
    height: 20px;
  }

  #cart-total {
    background-color: red;
    display: flex;
    flex-flow: column;
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-size: 12px;
  }

  .top-navbar {
    top: calc(96px + 24px);
  }

  .card.collapse {
    min-height: auto !important;
    max-height: 100% !important;
    border-radius: 8px;
  }

  .category-element {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    height: 28px;
    padding: 0px;
    letter-spacing: 0.45px;
    font-size: 12px;
  }

  $input-focus-active-label-transform: translateY(-1.25rem
      /* original value -1rem */
    ) translateY(0.1rem) scale(0.8);

  .form-outline .form-control~.form-notch {
    border-bottom: 1px solid blue;

    &>* {
      opacity: 0;
    }
  }

  .store-container {
    z-index: 95;
  }
</style>
