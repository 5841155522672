<script>
export default {name: "MainLayout"};
</script>

<script setup>
import {useCart} from '@/store';
import { storeToRefs } from 'pinia';
import HeaderApp from './HeaderApp.vue';
import FooterApp from "./FooterApp.vue";

const {cart} = storeToRefs(useCart());

// const capfirst = (value = '') => String(value).charAt(0).toUpperCase() + String(value).toLowerCase().slice(1, String(value).length);

</script>

<template>
  <div id="main-app">
    <header-app :cart="cart"/>
    <slot></slot>
    <footer-app />
  </div>
</template>

<style lang="scss" scoped>

  #main-app {
    display: flex;
    flex-flow: column;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 98;
  }

  #cart-icon {
    width: 20px;
    height: 20px;
  }

  #cart-total {
    background-color: red;
    display: flex;
    flex-flow: column;
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-size: 12px;
  }

  body.home-view {
    nav {
      background-color: var(--mdb-gray-50);

      .nav-item a.nav-link {
        letter-spacing: 0.5px;
        color: var(--mdb-pink);
        font-weight: 700;
      }
    }
  }


  @media screen and (min-width: 768px) {
    .contact-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .contact-info p {
    justify-content: center;
    font-size: 0.8em;
    padding-right: 1em;
    padding-left: 1em;
    color: #000;
  }

  .contact-info a {
    color: #000;
    text-decoration: underline;
  }

</style>
